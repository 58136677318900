<template>
    <div class="container-fluid">
        <Toolbar
            :click-add="showAdd"
            :click-edit="showEdit"
            :click-delete="showDelete"
            :click-refresh="showRefresh"
            :click-print="printStapel"
            :set-filter="loadDataSearch"
            :data   = "recs"
            :fields = "json_fields"
            type    = "xls"
            name    = "Stapel.xls"
        />
        <div class="row container-fluid m-0">
            <div class="col-md-3 p-0 mb-3 me-3">
                <div class="" id="boundary">
                    <table id="header" cellpadding="4" cellspacing="0" border="0" class="table table-hover table-strip">
                        <thead>
                            <tr class="">
                                <th width="50px">No. </th>
                                <th v-for="fl in filterByShow" :width="fl.width" :key="fl.name" class="">{{fl.name}}</th>
                            </tr>
                        </thead>
                    </table>
                </div>
                <div class="scrlt-y" v-bind:style="'height:'+  (screenHeight-220) +'px;'">
                    <table class="table table-hover table-strip table-bordered">
                        <tbody>
                            <tr v-for="(dt, index) in recs" :key="dt.s_id" class="w3-hover-pale-blue w3-small cursor">
                                <td width="50px" class="w3-border-right">
                                    <i :class="index == selectedRow ?'fa fa-caret-right':''"></i>&nbsp;{{dt.idx}}
                                </td>
                                <td v-on:click="setKlikRow(index, dt)" :style="'width:'+findObjFind('width','tgm_date')" class="w3-border-right w3-center">
                                    {{dt.tgm_date}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <!-- data barang -->
            <div class="col-md-9 row scrlt-y" v-bind:style="'height:'+  (screenHeight-150) +'px;'">
                    <!-- nav untuk menu mutasi -->
                    <div class="col-md-3 mb-0"  v-if="adminOnly()"  >
                        <select v-model="recgudangs.g_lid" class="form-select" @change="loadTrx">
                            <option value="" disabled>Pilih Gudang</option>
                            <option v-for="gudang in gudangs" :value="gudang.g_lid" :key="gudang.g_lid">{{ gudang.g_name }}</option>
                          </select>
                    </div>

                    <div class="">

                        <table id="header" cellpadding="0" cellspacing="0" border="0" class="table table-hover table-strip">
                            <thead>
                                <tr class="">
                                    <th v-for="fl in filterByShowArmada" :width="fl.width" :key="fl.name" class="">{{fl.name}}</th>
                                    <th width="50px"></th>
                                </tr>
                            </thead>
                        </table>
                    <div class="scrlt-y" v-bind:style="'height:'+  (screenHeight-240) +'px;'">
                        <table class="table table-hover table-strip table-bordered">
                            <tbody>
                                <tr v-for="(dtA, index) in recTrx" :key="dtA.as_id" v-on:click="setKlikArmada(index, dtA)" class="w3-hover-pale-blue w3-small cursor">
                                    <td :style="'width:'+findObjFindArmada('width','g_name')" >
                                        {{dtA.g_name}}
                                    </td>
                                    <td :style="'width:'+findObjFindArmada('width','i_name')">
                                        {{dtA.i_name}}
                                    </td>
                                    <td :style="'width:'+findObjFindArmada('width','tgt_qty')">
                                        {{dtA.tgt_qty}}
                                    </td>
                                    <td :style="'width:'+findObjFindArmada('width','status')">
                                        {{dtA.status}}
                                    </td>
                                    <td :style="'width:'+findObjFindArmada('width','keterangan')">
                                        {{dtA.keterangan}}
                                    </td>

                                    <!-- <td :style="'width:'+findObjFindArmada('width','as_keterangan')" class="w3-border-right">
                                        <div style="position: relative;" :style="'width:' + findObjFindArmada('width','as_keterangan')">
                                            <span v-html="setTextFind(dtA.as_keterangan,'as_keterangan')"></span>
                                            <div v-show="index == selectedArmada" style="position: absolute; top: -5px; right: 0px; text-align:center;" class="w3-opacity-off">
                                                <button style="padding: 5px 7px 5px 7px; margin-right:1px;" class="w3-btn w3-circle w3-tiny w3-theme-d3" v-on:click="EditArmada(dtA, index)"><i class="fa fa-pencil w3-large"></i></button>
                                                <button style="padding: 5px 7px 5px 7px; margin-right:5px;" class="w3-btn w3-circle w3-tiny w3-red" v-on:click="DeleteArmada(dtA, index)"><i class="fa fa-trash-o w3-large"></i></button>
                                            </div>
                                        </div>
                                    </td> -->
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    </div>
            </div>
        </div>
        <!-- pagging -->
        <div >
            <nav class="Page navigation h9 bg-light bg-gradient shadow">
                <div class="d-flex justify-content-center">
                    <ul class="pagination text-center">
                        <li class="page-item page-item active"><a :class="pages.halaman == 1 ?'dsb':''" class="page-link" @click="first()" href="#">First</a></li>
                        <li class="page-item page-item active"><a :class="pages.halaman == 1 ?'dsb':''" class="page-link" @click="prev()" href="#">«</a></li>
                        <li class="page-item page-item active"><a class="page-link px-0 px-md-2" href="#">Page {{pages.halaman}} sd {{pages.ttlhalaman}}</a></li>
                        <li class="page-item page-item active"><a :class="pages.halaman == pages.ttlhalaman ?'dsb':''" class="page-link" @click="next()" href="#">»</a></li>
                        <li class="page-item page-item active"><a :class="pages.halaman == pages.ttlhalaman ?'dsb':''" class="page-link" @click="last()" href="#">Last</a></li>
                        <li class="page-item page-item active"><a class="page-link  page-item active" href="#">Rec {{pages.ttlrec}}</a></li>
                        </ul>
                </div>
            </nav>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
import Config from "@/conf/Config.js";
import axios from "axios";
import "vue-search-select/dist/VueSearchSelect.css";
import { ModelListSelect } from "vue-search-select";
import moment from "moment";

import Toolbar from "@/components/Toolbar.vue";
import DatePicker from "@/components/DatePicker.vue";

import "datatables.net-bs5/js/dataTables.bootstrap5.min.js";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-buttons-bs5/js/buttons.bootstrap5"
import "datatables.net-buttons-bs5/css/buttons.bootstrap5.min.css"
import "datatables.net-buttons/js/buttons.html5.js";
import $ from "jquery";


export default {
    // head: {
    //     title: {
    //       inner: 'Main'
    //     }
    // },
    name : 'Stapel',
    components: {
        Toolbar,
        DatePicker,
        ModelListSelect
    },
    data() {
        return {
            gudang : localStorage.gudang,
            stgm_date : '2018-12-24 04:19:23',
            showdate :{tgm_date:false},
            screenHeight : 0,
            selectedRow : -1,
            selectedArmada : -1,
            selectedCust : -1,
            DatePickerFormat: 'YYYY-MM-DD',
            rec: {},
            recs: [],
            recgudangs : {
                g_lid: '1',
            },
            recTrx : [],
            recCust : {},
            recCusts : [],
            recItem :{},
            recItems : [],
            reci : [],
            recc : [],
            obji : {},
            kendaraans: [],
            gudangs: [],
            ikategori: [],
            pages : {halaman : 1, batas : 0, ttlhalaman : 0, ttlrec:0, limit:Config.perpage, search:''},
            filters : {
            	operator : 'AND',
                fields :[
                    {fieldFind : '', key : 'tgm_date', type: 'text', name : 'Date', filter : true, width : '250px', grid : true},

                ],
                fieldsArmada :[
                    {fieldFind : '', key : 'g_name', type: 'text', name : 'Gudang', filter : true, width : '70px', grid : true},
                    {fieldFind : '', key : 'i_name', type: 'text', name : 'Nama Barang', filter : true, width : '300px', grid : true},
                    {fieldFind : '', key : 'tgt_qty', type: 'text', name : 'Total', filter : true, width : '50px', grid : true},
                    {fieldFind : '', key : 'status', type: 'text', name : 'status', filter : true, width : '50px', grid : true},
                    {fieldFind : '', key : 'keterangan', type: 'text', name : 'Keterangan', filter : true, width : 'auto', grid : true},
                    // {fieldFind : '', key : 'as_stat', type: 'select', name : 'Status', filter : true, width : '80px', grid : false, valueFind: [{key:'T',label:'Aktif'},{key:'F',label:'Tidak Aktif'}]},
                ],

                find : []
            },
            /**Untuk transfer excel*/
            json_fields : {
                'ID' : 's_id',
                'TANGGAL' : 'tgm_date',
                'KETERANGAN' : 's_keterangan'
            },
        }
    },
    methods : {
        adminOnly() {
      // Mendapatkan nilai tingkat pengguna dari localStorage
      const userLevel = localStorage.getItem("gudang");

      // Mengembalikan true jika tingkat pengguna memenuhi syarat
      // Ubah logika ini sesuai dengan kebutuhan Anda
      return userLevel === "admin";
        },
        findObjFind(key, val){
            return this.findObj(this.filters.fields,'key',val)[key];
        },
        findObjFindArmada(key, val){
            return this.findObj(this.filters.fieldsArmada,'key',val)[key];
        },
        findField(val){
            return this.findObj(this.fieldRules, 'field',val);
        },
        findObj(obj, key, value) {
            let ketemu = 0;
            for (let pkey in obj) {
                if (obj[pkey][key] == value) {
                    break;
                } else {
                    ketemu++;
                }
            }
            if(obj[ketemu]!=undefined){
                return obj[ketemu];
            }else
                return null;
        },
        setTextFind(txt,field){
            let fieldvalue = "";
            let ketemu = false;
            let self = this;
            if(self.filters.find!==undefined )
                if ( self.filters.find.length >0 ) {
                    self.filters.find.map(function(value){
                        if(value.fieldsArmada ===field){
                            ketemu = true;
                        }
                        if(fieldvalue!=='null')
                            fieldvalue += ((fieldvalue!=='')?';':'')+ value.fieldValue ;
                        else
                            fieldvalue += ((fieldvalue!=='null')?';':'')+ value.fieldValue ;
                    });
                }
            if(ketemu){
                return self.sethighlight(txt,fieldvalue);
            }else{
                return txt;
            }
        },
        first(){
            let self = this;
            self.pages.halaman = 1;
            self.loadData();
        },
        prev(){
            let self = this;
            self.pages.halaman = self.pages.halaman - 1;
            self.loadData();
        },
        next(){
            let self = this;
            self.pages.halaman = self.pages.halaman + 1;
            self.loadData();
        },
        last(){
            let self = this;
            self.pages.halaman = self.pages.ttlhalaman;
            self.loadData();
        },
        setKlikRow(index, dt){
            let self = this;
            self.selectedRow = index;
            dt['']
            self.rec = Object.assign({}, dt);
            self.loadTrx(dt.tgm_i);
        },
        loadData(){
            let self = this;
            self.screenHeight = window.innerHeight;
            self.selectedRow = -1;
            self.rec = {};
            let params = {
                pfunction:'show',
                limit: self.pages.limit,
                page: self.pages.halaman,
                search: self.pages.search
            }
            axios.post("gudang/ApiMutasi.php",params).then(function (response) {
                let idx = (self.pages.limit*self.pages.halaman)-self.pages.limit+1;
                self.pages.ttlrec = parseFloat(response.data.ttlrec);
                self.pages.ttlhalaman = Math.ceil(parseFloat(response.data.ttlrec)/self.pages.limit);
                response.data.rec.map(function(val){
                    val['idx'] = idx;
                    idx++;
                });
                self.recs = response.data.rec;
            });
        },
        showRefresh(){
            this.pages.search = "";
            this.loadData();
        },
        loadDataSearch(filter){
            this.pages.search = filter;
            this.loadData();
        },
        async showAdd() {
            let self = this;
            self.stsAdd = true;

            let key = await axios.post("admin/ApiGetId.php",{
                pfunction : 'getId',
                vc_prefix : 'STP',
                vc_table : 'tb_stapel'
            });
            self.rec = {
                s_id : key.data,
                tgm_date : moment(new Date()).format('YYYY-MM-DD'),
                s_keterangan : '',
                s_stat : 'T'
            };
            document.getElementById("modal-form").style.display = "block";

        },
        showEdit(){
            let self = this;
            if (self.selectedRow < 0) {
                self.$toast.error('pilih gudang.!');
                return false;
            }else{
                self.stsAdd = false;
                document.getElementById('modal-form').style.display='block';
            }
        },
        EditArmada(dt, index){
            let self = this;
            self.stsAdd = false;
            document.getElementById('modal-armada').style.display='block';
        },
        saveData(event){
            let self = this;
            if (self.stsAdd == true) {
                self.rec.pfunction = "add";
            }else{
                self.rec.pfunction = "edit";
            }
            self.rec['userentry'] = localStorage.username;
            self.rec['tgm_date'] = moment(String(self.rec['tgm_date'])).format("YYYY-MM-DD");
            axios.post("master/ApiStapel.php",self.rec).then(function (response) {
                if(response.data.success == true){
                    self.$toast.success(response.data.msg);
                    if (self.stsAdd) {
                        self.recs.splice(0, 0, self.rec);
                    }else{
                        self.recs[self.selectedRow] = self.rec;
                    }
                    document.getElementById('modal-form').style.display='none';
                    self.selectedRow = -1;
                }else{
                    self.$toast.error(response.data.msg);
                }
            });
            event.preventDefault();
            return false;
        },
        saveArmada(event){
            let self = this;
            if (self.stsAdd == true) {
                self.recArmada.pfunction = "add";
            }else{
                self.recArmada.pfunction = "edit";
            }
            self.recArmada['userentry'] = localStorage.username;
            axios.post("master/ApiArmadaSt.php",self.recArmada).then(function (response) {
                if(response.data.success == true){
                    self.$toast.success(response.data.msg);
                    self.loadTrx(self.rec.s_id);
                    document.getElementById('modal-armada').style.display='none';
                }else{
                    self.$toast.error(response.data.msg);
                }
            });
            event.preventDefault();
            return false;
        },

        showDelete() {
            let self = this;
            if (self.selectedRow < 0) {
                self.$toast.error('pilih gudang.!');
                return false;
            }else{
                swal(
                    {
                        title: "Yakin Ingin Menghapus Data ..!",
                        text: "Data Yang sudah di hapus tidak akan bisa dikembalikan",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#DD6B55",
                        confirmButtonText: "Ya, Hapus Data",
                        cancelButtonText: "Tidak, Batal Hapus",
                        closeOnConfirm: false,
                        closeOnCancel: true,
                    },
                    function(isConfirm) {
                        if (isConfirm) {
                            let vparams = {
                                s_id : self.rec.s_id,
                                pfunction : "delete"
                            };
                            axios.post("master/ApiStapel.php",vparams).then(function (response) {
                                if (response.data.success == true) {
                                    swal({title: "Hapus..!",text: "Data Sudah terhapus",timer: 1000,type: "success"});
                                    self.$delete(self.recs, self.selectedRow);
                                }else{
                                    swal({title: "Delete",text: "Gagal delete data",type: "error",html: true});
                                }
                            });
                        }
                    }
                );
            }
        },
        DeleteArmada(dt, idx) {
            let self = this;
            swal(
                {
                    title: "Yakin Ingin Menghapus Data ..!",
                    text: "Data Yang sudah di hapus tidak akan bisa dikembalikan",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Ya, Hapus Data",
                    cancelButtonText: "Tidak, Batal Hapus",
                    closeOnConfirm: false,
                    closeOnCancel: true,
                },
                function(isConfirm) {
                    if (isConfirm) {
                        let vparams = {
                            as_id : dt.as_id,
                            pfunction : "delete"
                        };
                        axios.post("master/ApiArmadaSt.php",vparams).then(function (response) {
                            if (response.data.success == true) {
                                self.$delete(self.recTrx,idx);
                                swal({title: "Hapus..!",text: "Data Sudah terhapus",timer: 1000,type: "success"});
                            }else{
                                swal({title: "Delete",text: "Gagal delete data",type: "error",html: true});
                            }
                        });
                    }
                }
            );
        },

        loadTrx(dt){
            let self = this;
            self.selectedArmada = -1;
            let params = {
                pfunction:'showTrx',
                tgm_id : self.rec.tgm_i,
                gudang : self.gudang,
                gudangs : self.recgudangs.g_lid
            }
            axios.post("gudang/ApiMutasi.php",params).then(function (response) {
                self.recTrx = response.data.rec;
            });
        },
        loadCusts(dt){
            let self = this;
            self.selectedCust = -1;
            let params = {
                pfunction:'showbyid',
                as_id : dt,
            }
            axios.post("master/ApiCustomerSt.php",params).then(function (response) {
                self.recCusts = response.data.rec;
            });
        },
        loadItems(dt){
            let self = this;
            let params = {
                pfunction:'showbyid',
                cs_id : dt,
            }
            axios.post("master/ApiItemSt.php",params).then(function (response) {
                self.recItems = response.data.rec;
            });
        },
        setStatus(dt) {
            if (dt === "T") {
                return "<div class='w3-green w3-center w3-round' style='width:50px;margin-top:5px;'>Active</div>";
            } else {
                return "<div class='w3-red w3-center w3-round' style='width:50px;margin-top:5px;'>In Active<div>";
            }
        },
        setInit() {
            let self = this;
            axios.post("master/ApiKendaraan.php",{
                pfunction : 'showall'
            }).then(function (response) {
                self.kendaraans = response.data.rec;
            });
            axios.post("master/ApiGudang.php",{
                pfunction : 'showall'
            }).then(function (response) {
                const opsiTambahan = [
                    { g_lid: '1', g_name: 'All' },
                ];

                self.gudangs = [ ...opsiTambahan,...response.data.rec];
            });
        },
        printStapel(){
            let self = this;
            if (self.selectedRow < 0) {
                self.$toast.error('pilih stapel.!');
                return false;
            }else{
                moment.locale('id')
                self.rec['tgm_date'] = moment(String(self.rec['tgm_date'])).format("LL"),
                self.recTrx.map(function(val){
                    axios.post("master/ApiCustomerSt.php",{
                        pfunction:'showbyid',
                        as_id : val['as_id'],
                    }).then(function (recCus) {
                        val['cusST'] = recCus.data.rec;
                        recCus.data.rec.map(function(vl){
                            axios.post("master/ApiItemSt.php",{
                                pfunction:'showbyid',
                                cs_id : vl['cs_id'],
                            }).then(function (recItm) {
                                vl['itmST'] = recItm.data.rec;
                                self.$forceUpdate();
                                document.getElementById('idLoading').style.display='block';
                                setTimeout(() => {
                                    document.getElementById('idLoading').style.display='none';
                                    // document.getElementById('cetakDetail').style.display='block';
                                    let vhtml = document.getElementById('cetakDetail').innerHTML;
                                    if(print)
                                        self.printHtml(vhtml,'A4','portrait','5px 25px 5px 25px');
                                    else
                                        self.downloadFile('PO.xlsx',vhtml,'data:text/xls');
                                }, 1000);
                            });
                        });
                    });
                });
            }
        },
        printHtml(textHtml, kertas, bentuk, margin){
            let idprint = "de-print";
            let element = document.getElementById(idprint);
            if(element!=null)
                element.parentNode.removeChild(element);

            let iframe = document.createElement('iframe');
            iframe.setAttribute('id', idprint);
            iframe.setAttribute('name', idprint);
            iframe.setAttribute('frameborder', 0);
            iframe.setAttribute('display', 'none');
            iframe.setAttribute('width', 0);
            iframe.setAttribute('height', 0);

            document.body.appendChild(iframe);
            let newWin = window.frames[idprint];
            textHtml = `<!DOCTYPE html>
            <html lang="en">
                <head>
                    <meta charset="UTF-8">
                    <meta name="viewport" content="width=device-width, initial-scale=1.0">
                    <meta http-equiv="X-UA-Compatible" content="ie=edge">
                    <link rel="stylesheet" href="./assets/css/w3.css">
                    <link rel="stylesheet" href="./assets/css/report.css">
                    <title>Document</title>
                    <style>
                    .pindah-halaman {page-break-after: always;}
                    @media print {
                        @page {
                            size: ${kertas} ${bentuk};
                            margin : ${margin};
                        }
                    }
                    @font-face {
                        font-family: barcodefont;
                        src: url(assets/font-code/BarcodeFont.ttf);
                    }
                    .debarcode{
                        font-family: barcodefont;
                    }
                    </style>
                </head>
                <body onload="window.print()">
                    ${textHtml}
                </body>
            </html>`;
            newWin.document.write(textHtml);
            // console.log(textHtml);
            newWin.document.close();
        },
    },
    filters: {
        toRp(value) {
            return `${Intl.NumberFormat("de-DE").format(value)}`;
        },
        pNumber(num) {
            let x = num.replace(/\D/g, '').match(/(\d{0,4})(\d{0,4})(\d{0,4})/);
            return !x[2] ? x[1] : '(' + x[1] + ')-' + x[2] + (x[3] ? '' + x[3] : '');
        }
    },
    watch: {
        // 'moveCenter': function(){
        //     console.log("OK");
        // }
    },
    computed :{
        filterByShow(){
            return  this.filters.fields.filter(event => {
                return event.grid == true;
            });
        },
        filterByShowArmada(){
            return  this.filters.fieldsArmada.filter(event => {
                return event.grid == true;
            });
        },
        filterByShowCust(){
            return  this.filters.fieldsCust.filter(event => {
                return event.grid == true;
            });
        },
        filterByShowItem(){
            return  this.filters.fieldsItem.filter(event => {
                return event.grid == true;
            });
        }
    },
    mounted(){
        this.screenHeight = window.innerHeight;
        this.setInit();
        this.loadData();
    }
};
</script>